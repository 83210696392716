.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
