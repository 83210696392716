@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
@layer base {
  /* Donot remove root */
  :root {
    --primary-color-1: #413f3f;
    --primary-color-2: #000000;
    --primary-color: #c0c3c7;
    --secondary-color: #dadfe2;
  }
}
[class="light"] {
  --color-background: #f8f8f8;
}
[class="light"] {
  --color-primary: #7f56d9;
  --color-primary-hover: #6941c6;
  --color-primary-focus: #6941c6;
  --color-primary-txt: #f5f5f5;
  --color-primary-disabled: #e7e7e7;
  --color-primary-dark: #000000;

  --color-secondary: #ffffff;
  --color-secondary-hover: #f9fafb;
  --color-secondary-hover-1: #f5f5f5;
  --color-secondary-focus: #b0b0b0;
  --color-secondary-txt: #292929;
  --color-secondary-txt-1: #3e3e3e;

  --color-secondary-disabled: #848484;
  --color-secondary-disabled-txt: #848484;

  --color-logo: #f5f5f5;
  --color-logo2: #888888;
  --color-heading-1: #000000;
  --color-heading-2: #000000;
  --color-description: #475467;
  --color-description-1: #667085;

  --color-label: #344054;
  --color-placeholder: #888888;

  --color-checked: #292929;
  --color-checked-tick: #ffffff;

  --color-table-heading: #000000;
  --color-table-body: #3e3e3e;

  --color-background: #ffffff;
  --color-background-1: #ffffff;
  --color-background-2: #ffffff;
  --color-background-3: #6f54c6;
  --color-background-4: #f8f8f8;
  --color-background-input: #ffffff;
  --color-background-active: #e6feec;
  --color-background-inactive: #ffcecb;
  --color-background-light: #f8f8f8f8;
  --color-background-primary: #9178ed66;

  --color-green-light: #ecfdf3;
  --color-green-light-txt: #299e47;
  --color-red-light: #fef3f2;
  --color-red-light-txt: #f04438;

  --color-border: #d9dde4;
  --color-border-1: #303d50;
  --color-error-red: #f97066;
}
[class="dark"] {
  --color-primary: #7f56d9;
  --color-primary-hover: #6941c6;
  --color-primary-focus: #6941c6;
  --color-primary-txt: #bfbfbf;
  --color-primary-disabled: #2a2a2a;

  --color-secondary: #364152;
  --color-secondary-hover: #202939;
  --color-secondary-hover-1: #e3e3e3;
  --color-secondary-focus: #848484;
  --color-secondary-txt: #b5b4b4;
  --color-secondary-txt-1: #848484;
  --color-secondary-disabled: #4b5565;
  --color-secondary-disabled-txt: #848484;

  --color-logo: #e5e5e5;
  --color-logo2: #b5b4b4;
  --color-heading-1: #bdbdbd;
  --color-heading-2: #b5b4b4;
  --color-description: #e3e8ef;
  --color-description-1: #a3a3a3;
  --color-table-heading: #b5b4b4;
  --color-table-body: #848484;

  --color-checked: #b5b4b4;
  --color-checked-tick: #000000;

  --color-label: #e3e8ef;
  --color-placeholder: #b5b4b4;

  --color-background: #000000;
  --color-background-1: #262626;
  --color-background-2: #000000;
  --color-background-3: #6f54c6;
  --color-background-4: #101010;
  --color-background-5: #ffe1e5;
  --color-background-input: #101010;
  --color-background-active: #e6feec;
  --color-background-inactive: #ffcecb;
  --color-background-light: #000000;
  --color-background-primary: #42327d66;

  --color-green-light: #364152;
  --color-green-light-txt: #299e47;
  --color-red-light: #364152;
  --color-red-light-txt: #ff170a;

  --color-border: #555555;
  --color-border-1: #303d50;
  --color-error-red: #f97066;
  --color-border-2: #555555;
  --color-border-3: #262626;
  --color-border-4: #f5f5f5;
}
.quill > * {
  border: 2px solid !important;
  border-color: #555555 !important;
  color: inherit !important;
}
.quill > .ql-toolbar {
  border-color: #555555 !important;
  border-top: 0px !important;
  border-radius: 0 0 10px 10px;
  color: currentColor !important;
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}

.quill > .ql-container {
  font-size: inherit;
  border-radius: 10px 10px 0 0;
  height: 400px;
}

.ql-toolbar {
  position: absolute;
  width: 100%;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.ql-container {
  padding-bottom: 50px;
  position: relative;
}

.ql-editor {
  padding-bottom: 50px;
}
